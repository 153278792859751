import { useMemo, useEffect, useReducer, useCallback } from 'react'

import { useAppDispatch } from 'global/redux/toolkit/hooks'
import { TabsProps } from 'global/components/lib/Tabs/Tabs'
import { useFormatMessage } from 'global/lib/localization'

import { reset as resetSettings, resetError as resetSettingsError } from 'sen/redux/features/settings/settingsSlice'

import { OnToggleView } from './allowedSendersTab/useAlllowedSendersTabLogic'

export type UpdateTabSettings = (
  updateSettings: any,
  isSaveButtonDisabled: boolean,
  isAllowedSenderTableView?: boolean,
  onToggleAllowedSender?: OnToggleView
) => void

export interface SettingsProps {
  backButton: { showBackButton: boolean; handleBackButton: () => void }
  onSave: () => void
  isSaveButtonDisabled: boolean
  pageTitle: string
  settingsTabsConfig: {
    activeTab: number
    updateTabSettings: UpdateTabSettings
  }
  showSaveButton: boolean
  tabsConfig: TabsProps
}

const BASE_I18N_KEY = 'sen.app.settings'
const TABS = ['spear_phishing', 'ato', 'allowed_senders', 'syslog_integration']

export default function useSettingsLogic(defaultTab = 0): [SettingsProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, setState] = useReducer(
    (_state: any, newState: any) => ({
      ..._state,
      ...newState
    }),
    {
      activeTab: TABS[0],
      isSaveButtonDisabled: true,
      isAllowedSenderTableView: true,
      onToggleAllowedSender: null,
      updateSettings: null
    }
  )

  // init
  useEffect(() => {
    // unmount
    return () => {
      dispatch(resetSettings())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabsConfig = useMemo(() => {
    return {
      activeTab: state.activeTab,
      tabs: TABS.map((tab: any) => ({
        id: tab,
        onClick: () => {
          dispatch(resetSettingsError())
          setState({ activeTab: tab })
        },
        label: formatMessage(`tabs.${tab}`)
      })),
      vertical: true
    }
  }, [state.activeTab, formatMessage, dispatch])

  const updateTabSettings = useCallback(
    (updateSettings, isSaveButtonDisabled, isAllowedSenderTableView, onToggleAllowedSender) => {
      // Don’t follow this pattern to pass a function (updateSettings and onToggleAllowedSender) to the state, as it’s not the usual approach.
      // We'll restructure IP settings logic when we begin working on globalizing the settings.
      setState({
        updateSettings,
        isSaveButtonDisabled,
        isAllowedSenderTableView,
        onToggleAllowedSender
      })
    },
    []
  )

  const showSaveButton = useMemo(() => {
    return state.activeTab !== 'syslog_integration' && !state.isAllowedSenderTableView
  }, [state.activeTab, state.isAllowedSenderTableView])

  const backButton = useMemo(() => {
    return {
      showBackButton: !state.isAllowedSenderTableView && state.activeTab === 'allowed_senders',
      handleBackButton: state.onToggleAllowedSender
    }
  }, [state.activeTab, state.isAllowedSenderTableView, state.onToggleAllowedSender])

  const onSave = useCallback(() => {
    dispatch(state.updateSettings)
  }, [dispatch, state])

  return useMemo(() => {
    return [
      {
        backButton,
        isSaveButtonDisabled: state.isSaveButtonDisabled,
        onSave,
        pageTitle: formatMessage('page_title'),
        settingsTabsConfig: {
          activeTab: TABS.indexOf(state.activeTab),
          updateTabSettings
        },
        showSaveButton,
        tabsConfig
      }
    ]
  }, [
    backButton,
    formatMessage,
    onSave,
    showSaveButton,
    state.activeTab,
    state.isSaveButtonDisabled,
    tabsConfig,
    updateTabSettings
  ])
}
