import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  TextField,
  Grid,
  Alert,
  Tooltip,
  Box
} from '@barracuda-internal/bds-core'
import { Edit as EditIcon, DeleteForever as DeleteForeverIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import Pager from 'global/components/lib/grid/pager/Pager'

import useAllowedSendersTabLogic from 'sen/components/pages/settings/allowedSendersTab/useAlllowedSendersTabLogic'
import settingsStyles from 'sen/components/pages/settings/settingsStyles'
import styles from 'sen/components/pages/settings/allowedSendersTab/allowedSendersTabStyles'
import { UpdateTabSettings } from 'sen/components/pages/settings/useSettingsLogic'

const BASE_I18N_KEY = 'sen.app.settings.allowed_senders_tab'

type AllowedSendersTabProps = { updateTabSettings: UpdateTabSettings }

export const AllowedSendersTab: React.FC<AllowedSendersTabProps> = ({ updateTabSettings }) => {
  const classes = styles()
  const settingsClasses = settingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [isTabInProgress, tabView, tableConfig, allowedSender, formHandlers, formStates] = useAllowedSendersTabLogic(
    updateTabSettings
  )

  return useMemo(
    () => (
      <Grid container className={settingsClasses.tabContent} direction="column">
        {formStates.error && (
          <Alert className={settingsClasses.error} severity="error">
            {errorFormatMessage(formStates.error)}
          </Alert>
        )}

        {isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}

        <Grid container className={classes.tabContent} direction="column">
          {tabView.isTableView && (
            <Grid container direction="column">
              <Grid className={classes.addButton} container alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={() => {
                    tabView.toggle()
                  }}
                  disabled={isTabInProgress}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  {formatMessage('add_allowed_sender')}
                </Button>
              </Grid>

              <Grid className={classes.tableArea} data-table="mailboxes" item xs={12}>
                <DataTable
                  className={`${
                    isTabInProgress || tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                  } allowed-senders-table`}
                  data={tableConfig.tableData}
                  {...tableConfig.pageConfig}
                  pager={Pager}
                  rowRender={(trElement, props) => {
                    const trProps = {
                      ...(props.dataItem.isDeleting && { className: `${trElement.props.className} deleted-row` })
                    }

                    return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
                  }}
                >
                  <DataTableNoRecords>
                    <Typography>{!tableConfig.tableData.total && formatMessage('empty_table')}</Typography>
                  </DataTableNoRecords>
                  <DataTableColumn
                    field={tableConfig.columns.SENDER_EMAIL_OR_DOMAIN}
                    {...tableConfig.columnsConfig[tableConfig.columns.SENDER_EMAIL_OR_DOMAIN]}
                    title={formatMessage(`table_columns.${tableConfig.columns.SENDER_EMAIL_OR_DOMAIN}`)}
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={classes.cell}>
                        <CellText ellipsis className={classes.boldCell}>
                          {dataItem.pattern}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.COMMENT}
                    {...tableConfig.columnsConfig[tableConfig.columns.COMMENT]}
                    title={formatMessage(`table_columns.${tableConfig.columns.COMMENT}`)}
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={classes.cell}>
                        <CellText ellipsis className={classes.normalCell}>
                          {dataItem.remarks}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.EDIT}
                    {...tableConfig.columnsConfig[tableConfig.columns.EDIT as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={classes.cell}>
                        <CellText>
                          {!isTabInProgress && (
                            <Tooltip title={formatMessage('edit_allowed_sender')}>
                              <Box>
                                <EditIcon onClick={dataItem.onEdit} className={classes.icon} />
                              </Box>
                            </Tooltip>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.DELETE}
                    {...tableConfig.columnsConfig[tableConfig.columns.DELETE as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={classes.cell}>
                        <CellText>
                          {!isTabInProgress && (
                            <Tooltip title={formatMessage('delete_allowed_sender')}>
                              <Box>
                                <DeleteForeverIcon onClick={dataItem.onDelete} className={classes.icon} />
                              </Box>
                            </Tooltip>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
              <Grid container className={classes.footer}>
                <Typography>
                  {formatMessage('caution', {
                    b: (txt: string) => <b key={uuidv4()}>{txt}</b>
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!tabView.isTableView && (
            <Grid container direction="column">
              <Typography className={classes.title}>
                {formatMessage(allowedSender.id ? 'edit_allowed_sender' : 'add_new_allowed_sender')}
              </Typography>
              <Grid container className={classes.formRow} alignItems="center" direction="column">
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      required
                      label={formatMessage('sender_email_or_domain')}
                      className={classes.textField}
                      onChange={(e: any) => {
                        formHandlers.onUpdateAllowedSender({ pattern: e.target?.value?.trim() })
                      }}
                      value={allowedSender.pattern}
                      disabled={isTabInProgress}
                      error={!formStates.isValidAllowedSender}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.formRow} alignItems="center" direction="column">
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      label={formatMessage('comment')}
                      className={classes.textField}
                      onChange={(e: any) => {
                        formHandlers.onUpdateAllowedSender({ remarks: e.target.value })
                      }}
                      value={allowedSender.remarks}
                      disabled={isTabInProgress}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [
      classes,
      settingsClasses,
      formatMessage,
      errorFormatMessage,
      isTabInProgress,
      tableConfig,
      tabView,
      formHandlers,
      formStates,
      allowedSender
    ]
  )
}

export default AllowedSendersTab
