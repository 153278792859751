import { useCallback, useMemo, useState } from 'react'
// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'

import {
  setCurrentAccessToken,
  setCurrentBccAccount,
  reset as resetAccessToken
} from 'global/redux/features/accessToken/accessTokenSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { config } from 'global/lib/config'
import useFeatureLib from 'global/lib/feature/useFeature'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { NavbarContextMenuO365Props } from 'global/components/lib/layout/navbar'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'
import gotoReportPage from 'sen/lib/gotoReportPage'

export interface SenLayoutLogic {
  initAccount: string
  navbarContextMenuO365Props?: NavbarContextMenuO365Props
  onSelectAccountCallback: (selectedAccount: BDSAccountSwitcherAccount) => void
}

export default function useLayoutLogic(): SenLayoutLogic[] {
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const [featureLib] = useFeatureLib()

  const { accessTokenId, bccAccount } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    bccAccount: _stores.accessToken.bccAccount || ''
  }))

  const [initAccount, setInitAccount] = useState(accessTokenId || bccAccount)
  const accounts = userDataLib.getAccounts()

  // Any logic updates in onSelectAccountCallback will be done in BNFIR-3347
  const onSelectAccountCallback = useCallback(
    async (selectedAccount: BDSAccountSwitcherAccount) => {
      const account = userDataLib.getAccountByBccId(selectedAccount.id)
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, {
        selectedAccountId: selectedAccount.id
      })
      setInitAccount(selectedAccount.id)

      // If the account has a nestedAccounts property, it indicates that this account is a bccAccount (parent account) without an accessTokenId.
      if (selectedAccount.nestedAccounts) {
        dispatch(resetAccessToken())
        dispatch(setCurrentBccAccount(selectedAccount.id))

        const senSerialState = account?.bccSerialNumbers.sen.state
        if (senSerialState !== 'A') {
          routesConfig.START_TRIAL.goto()
          return
        }

        routesConfig.SIGNIN_CONNECT.goto()
      }
      // also need to verify if bccId changes when switching from bccId to accessTokenId
      else if (selectedAccount.id !== accessTokenId || selectedAccount.id !== accessTokenLib.getCurrentBccAccount()) {
        await dispatch(setCurrentAccessToken(selectedAccount.id))

        const validatedToken = userDataLib.getAccountByAccessToken(selectedAccount.id)
        if (validatedToken) {
          dispatch(setCurrentBccAccount(validatedToken.bccId as string))
        }

        if (!productLib.hasSentinelProduct(selectedAccount.id) && productLib.hasDFPProduct(selectedAccount.id)) {
          routesConfig.DOMAIN_FRAUD.goto({ reportId: selectedAccount.id })
        } else {
          routesConfig.DASHBOARD.goto({ reportId: selectedAccount.id })
        }
      }
    },
    [accessTokenId, dispatch, productLib, userDataLib]
  )

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(
    () => ({
      successCb: (data: any, scanType: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CONNECT_O365_SUCCESSFUL, {
          accessTokenId: data.accessToken.id
        })
        if (scanType === config.SCAN_TYPES.SENTINEL) {
          window.location.replace(`${config.domains.ets}/report/${data.accessToken.id}`)
        } else {
          gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
        }
      },
      showScanTypeSelection:
        (accounts && featureLib.hasSentinelFeatureForAnyAccessToken()) || featureLib.hasETSReadOnlyForAnyAccessToken(),
      accounts
    }),
    [accounts, featureLib]
  )

  return useMemo(
    () => [
      {
        initAccount,
        navbarContextMenuO365Props,
        onSelectAccountCallback
      }
    ],
    [initAccount, navbarContextMenuO365Props, onSelectAccountCallback]
  )
}
